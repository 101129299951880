import styled from "styled-components";
import akauan from "../../../assets/images/akauan.jpg";

export default function About() {
	return (
		<Box>
			<Text>Fundador</Text>
			<Container>
				<div>
					<ContainerImage>
						<img src={akauan} alt="rta" draggable="false" />
					</ContainerImage>
					<ContainerText>
						<div>
							<p>Akauan Oliveira</p>
							<p>Crefito: 294713-F</p>
						</div>
					</ContainerText>
				</div>
				<div>
					<ul>
						<li>Graduado fisioterapia em 2019</li>
						<li>Psicanalista pela Metropolitana</li>
						<li>
							Especialista em fisioterapia
							neurofuncional pela UNB
						</li>
						<li>
							Especialista em biomecânica e terapia clínica
							postural pela Albert Einstein
						</li>
						<li>Acupunturista pela Albert Einstein</li>
						<li>
							Formado pelo Método Reequilíbrio Tóracoabdominal
							(RTA)
						</li>
						<li>
							Formado no conceito Mulligan pelo International
							Mulligan Concept
						</li>
						<li>
							Formação internacional Master em hipnose clínica
							pelo Instituto Lucas Naves
						</li>
					</ul>
				</div>
			</Container>
		</Box>
	);
}

const Box = styled.div`
	height: 100%;

	@media (max-width: 500px) {
		width: 85%;
	}
`;

const Text = styled.p`
	margin-top: 100px;
	font-size: 40px;
	text-align: center;
	font-family: "Source Sans Pro", sans-serif;
	font-weight: 800;

	@media (max-width: 500px) {
		margin-top: 80px;
	}
`;

const Container = styled.div`
	margin-top: 15px;
	background-color: #f5f7fa;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	padding: 10px;
	border-radius: 10px;
	display: flex;
	align-items: center;

	ul {
		max-width: 350px;
		margin-left: 30px;
		list-style-type: circle;

		li {
			margin-bottom: 10px;
		}
	}

	@media (max-width: 500px) {
		flex-direction: column;

		ul {
			margin-top: 10px;
		}
	}
`;

const ContainerImage = styled.div`
	width: 280px;
	height: 280px;

	img {
		border-radius: 5%;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	@media (max-width: 500px), (max-width: 995px) {
		width: 100%;
	}
`;

const ContainerText = styled.div`
	padding: 10px;
	text-align: center;
	max-width: 280px;

	p:first-child {
		font-weight: bold;
		font-size: 20px;
		margin-bottom: 5px;
	}
`;
