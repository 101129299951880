import styled from "styled-components";
import logo from "../../assets/images/logo.png";
import { useState } from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import ListIcon from "@mui/icons-material/List";

export default function TopBar() {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	function scrollToTop() {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
	}

	const scroll = (location: string) => {
		const section = document.getElementById(`${location}`);
		if (section) {
			section.scrollIntoView({
				behavior: "smooth",
				block: "start",
				inline: "nearest",
			});
		}
	};

	return (
		<Box>
			<Logo>
				<img src={logo} alt="logo" draggable="false" />
			</Logo>
			<MenuDesktop>
				<TextMenu onClick={scrollToTop}>
					<p>Início</p>
					<span></span>
				</TextMenu>
				<TextMenu onClick={() => scroll("spescialties-scroll")}>
					<p>Especialidades</p>
					<span></span>
				</TextMenu>
				<TextMenu onClick={() => scroll("courses-scroll")}>
					<p>Cursos</p>
					<span></span>
				</TextMenu>
				<TextMenu onClick={() => scroll("depositions-scroll")}>
					<p>Depoimentos</p>
					<span></span>
				</TextMenu>
				<TextMenu onClick={() => scroll("contacts-scroll")}>
					<p>Contato</p>
					<span></span>
				</TextMenu>
			</MenuDesktop>

			<MenuMobile>
				<Button
					id="basic-button"
					aria-controls={open ? "basic-menu" : undefined}
					aria-haspopup="true"
					aria-expanded={open ? "true" : undefined}
					onClick={handleClick}
				>
					<ListIcon sx={{ fontSize: "40px" }} />
				</Button>
				<Menu
					id="basic-menu"
					anchorEl={anchorEl}
					open={open}
					onClose={handleClose}
					MenuListProps={{
						"aria-labelledby": "basic-button",
					}}
				>
					<MenuItem onClick={scrollToTop}>Início</MenuItem>
					<MenuItem onClick={() => scroll("spescialties-scroll")}>
						Especialidades
					</MenuItem>
					<MenuItem onClick={() => scroll("courses-scroll")}>
						Cursos
					</MenuItem>
					<MenuItem onClick={() => scroll("depositions-scroll")}>
						Depoimentos
					</MenuItem>
					<MenuItem onClick={() => scroll("contacts-scroll")}>
						Contato
					</MenuItem>
				</Menu>
			</MenuMobile>
		</Box>
	);
}

const Box = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-around;
	width: 100%;
	height: 100px;
	background-color: white;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 2;
`;

const Logo = styled.div`
	width: 170px;
	padding: 2px;
	img {
		width: 100%;
		height: 100%;
	}
`;

const MenuDesktop = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	div {
		margin-right: 25px;

		&:last-child {
			margin-right: 0;
		}
	}

	@media (max-width: 995px) {
		display: none;
	}
`;

const MenuMobile = styled.div`
	display: none;

	@media (max-width: 995px) {
		display: block;
	}
`;

const TextMenu = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	font-size: 18px;
	font-weight: bold;
	color: gray;
	cursor: pointer;

	span {
		width: 100%;
		height: 4px;
		transition: background-color 0.5s ease-in;
		margin-top: 3px;
		border-radius: 10px;
	}

	&:hover {
		span {
			background-color: lightgray;
		}
	}
`;
