import Specialty from "../../../components/Specialty";
import styled from "styled-components";
import acupuntura from "../../../assets/images/acupuntura.jpg";
import neurologica from "../../../assets/images/fisioterapia-neurologica.jpg";
import fisica from "../../../assets/images/fisica.jpg";
import hipnose from "../../../assets/images/hipnose.jpg";
import respiratorio from "../../../assets/images/respiratorio.jpg";

export default function Specialties() {
	return (
		<>
			<span id="spescialties-scroll"></span>
			<Text>Especialidades</Text>
			<Box>
				<Container>
					<Specialty
						title="Fisioterapia neurofuncional"
						text="A Fisioterapia Neurofuncional ajuda a tratar e gerenciar os sintomas daqueles que lidam com danos ao sistema nervoso para melhorar a mobilidade e funcionalidade na vida cotidiana. O principal objetivo dessa área é diminuir as sequelas de pacientes que possuem alteração em seus movimentos, ou que apresentam paralisia em algum membro do corpo."
						image={neurologica}
					/>
					<Specialty
						title="Fisioterapia cardiorrespiratória"
						text="A Fisioterapia cardiorrespiratória tem como objetivo a prevenção e o tratamento de doenças que atingem o sistema cardiorrespiratório, como a asma, a bronquite, insuficiência respiratória, tuberculose, insuficiência cardíaca, arritmia cardíaca, entre outras. Os exercícios cardiorrespiratórios possuem grandes benefícios. A oxigenação além de melhorar a capacidade pulmonar, também é indispensável para que o organismo continue funcionando saudavelmente."
						image={respiratorio}
					/>
					<Specialty
						title="Fisioterapia esportiva"
						text="A Fisioterapia Esportiva atua no período pré e pós lesão, com a finalidade de reabilitar o atleta no retorno à prática de atividades desportivas (Black to play). Trabalha também para aumentar a longevidade esportiva do atleta, extrair o máximo de sua perfomace e garantir que o atleta esteja presente nas conquistas esportivas de sua modalidade."
						image={fisica}
					/>
					<Specialty
						title="Acupuntura"
						text="A Acupuntura busca a recuperação do organismo como um todo, indução de processos regenerativos, normalização das funções alteradas, reforço do sistema imunológico e controle da dor. A fisioterapia utiliza o método para a funcionalidade do paciente, além de desativar os “pontos de gatilhos” de dor, as agulhadas melhoram a circulação, já que o organismo aumenta a irrigação sanguínea quando percebe um corpo estranho, gerando um reequilíbrio holístico."
						image={acupuntura}
					/>
					<Specialty
						title="Hipnoterapia"
						text="Libertação de traumas que podem estar impactando a saúde emocional, mental e física
						Eliminação de maus hábitos e construção de hábitos positivos
						Ressignificação de crenças que podem estar prejudicando a pessoa
						Elaboração de eventos passados com o objetivo de esclarecer e dessensibilizar, ganhando outras perspectivas que sejam benéficas para o indivíduo
						Trazer as sugestões que a pessoa precisa de acordo com suas necessidades. "
						image={hipnose}
					/>
				</Container>
			</Box>
		</>
	);
}

const Box = styled.div`
	height: 100%;
	width: 85%;
	background-color: lightgray;
	border-radius: 20px;

	@media (max-width: 500px) {
		width: 100%;
		border-radius: 0;
	}
`;

const Container = styled.div`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
	align-items: center;
	margin: 20px auto 50px auto;

	@media (max-width: 995px) {
		justify-content: center;
	}

	@media (max-width: 500px) {
		flex-direction: column;
		flex-wrap: initial;
		width: 90%;
	}
`;

const Text = styled.p`
	margin-top: 100px;
	font-size: 40px;
	text-align: center;
	font-family: "Source Sans Pro", sans-serif;
	font-weight: 800;
	margin-bottom: 30px;

	@media (max-width: 500px) {
		margin-top: 100px;
	}
`;
