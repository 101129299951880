import styled from "styled-components";
import { TSpecialty } from "./types";

export default function Specialty({
	title,
	text,
	image,
	color = "white",
	date = "default",
}: TSpecialty) {
	return (
		<>
			<Box color={color}>
				<ContainerImage>
					<img src={image} alt="rta" draggable="false" />
				</ContainerImage>
				<ContainerText>
					<p>{title}</p>
					<p>{text}</p>
					<p>{date !== "default" ? date : ""}</p>
				</ContainerText>
			</Box>
		</>
	);
}

const Box = styled.div<Props>`
	width: 360px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: ${(props) => props.color};
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	border-radius: 15px;
	margin-top: 30px;
	height: 500px;
	transition: height 1s ease 0s;

	@media (max-width: 500px), (max-width: 995px) {
		transition: none;
		width: 100%;
		height: 100%;
		padding-top: 20px;
	}

	&:hover {
		height: 650px;

		div:last-child {
			p:nth-child(2),
			p:last-child {
				display: flex;
				animation: slide-text 1s ease 0s 1 normal forwards;

				@media (max-width: 500px), (max-width: 995px) {
					animation: none;
				}
			}
		}
	}
`;

const ContainerImage = styled.div`
	width: 270px;
	height: 270px;

	img {
		border-radius: 5%;
		width: 100%;
		height: 100%;
		object-fit: cover;
		box-shadow: rgba(0, 0, 0, 0.5) 0px 2px 5px;
	}

	@media (max-width: 500px), (max-width: 995px) {
		width: 100%;
		padding: 0 20px;
	}
`;

const ContainerText = styled.div`
	padding: 10px;
	width: 80%;
	font-family: "Lexend", sans-serif;

	p:first-child {
		font-size: 27px;
		font-weight: 600;
		text-align: center;
	}

	p:nth-child(2) {
		font-size: 15px;
		display: none;
		margin-top: 10px;
	}

	p:last-child {
		font-size: 25px;
		display: none;
		align-items: center;
		justify-content: center;
		margin-top: 15px;
	}

	@media (max-width: 500px), (max-width: 995px) {
		p:first-child {
			font-size: 30px;
		}

		p:nth-child(2) {
			display: flex;
			padding: 20px 0;
		}

		p:last-child {
			display: flex;
			padding: 20px 0;
		}
	}
`;

interface Props {
	isDate?: string;
	color?: string;
}
