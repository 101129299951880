import Carousel from "react-material-ui-carousel";
import { ICarousel } from "./types";

export default function CarouselComp({
	children,
	height,
	indicator,
	navButtons,
}: ICarousel) {
	return (
		<Carousel
			height={height}
			indicators={indicator}
			navButtonsAlwaysVisible={navButtons}
		>
			{children}
		</Carousel>
	);
}
