import styled from "styled-components";
import CarouselComp from "../../../components/Carousel";
import acupuntura from "../../../assets/images/acupuntura-carousel.jpeg";
import fisica from "../../../assets/images/fisica-carousel.jpg";
import manipulacoes from "../../../assets/images/manipulacoes-carousel.jpg";
import mulligan from "../../../assets/images/mulligan-carousel.jpg";
import ventosaterapia from "../../../assets/images/ventosaterapia-carousel.jpg";

export default function Initial() {
	const items = [
		{ image: acupuntura, alt: "acupuntura" },
		{ image: ventosaterapia, alt: "ventosaterapia" },
		{ image: mulligan, alt: "mulligan" },
		{ image: manipulacoes, alt: "manipulacoes" },
		{ image: fisica, alt: "fisica" },
	];

	return (
		<Box>
			<ContainerCarousel>
				<CarouselComp height={650} indicator={true} navButtons={true}>
					{items.map((item, index) => (
						<ContainerImage key={item.alt}>
							<img
								src={item.image}
								alt={item.alt}
								draggable="false"
							/>
						</ContainerImage>
					))}
				</CarouselComp>
			</ContainerCarousel>
		</Box>
	);
}

const Box = styled.div`
	width: 85%;
	display: flex;
	height: 88vh;
	justify-content: space-evenly;
	align-items: center;

	@media (max-width: 500px) {
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 100%;
		width: 100%;
	}

	@media (max-width: 995px) {
		height: 100%;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
`;

const ContainerCarousel = styled.div`
	width: 100%;

	img {
		border-radius: 20px;
	}

	@media (max-width: 500px) {
		margin-top: 20px;
		width: 100%;
	}
`;

const ContainerImage = styled.div`
	width: 100%;
	height: 100%;

	img {
		width: 100%;
		height: 100%;
	}

	@media (max-width: 500px) {
		img {
			width: 100%;
			object-fit: contain;
		}
	}
`;
