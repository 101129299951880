import styled from "styled-components";
import logo from "../../../assets/images/logo.png";
import SocialBar from "../../SocialBar";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";

export default function Footer() {
	return (
		<Box>
			<span id="contacts-scroll"></span>
			<Container>
				<Logo>
					<img src={logo} alt="logo" draggable="false" />
				</Logo>

				<BoxContacts>
					<p>Contato:</p>
					<div>
						<LocalPhoneIcon />
						<p>(61) 98300-5864</p>
					</div>
					<div>
						<MailOutlineIcon />
						<p>akauan.oliveira@hotmail.com</p>
					</div>
				</BoxContacts>
				<Socials>
					<SocialBar />
				</Socials>
			</Container>
		</Box>
	);
}

const Box = styled.div`
	width: 80%;
	height: 100%;
	background-color: #ededed;
	padding: 30px;
	margin: 40px auto 30px auto;
	border-radius: 20px;

	@media (max-width: 500px) {
		width: 100%;
		border-radius: 0;
		margin-bottom: 0;
	}
`;

const Container = styled.div`
	display: flex;
	justify-content: space-around;
	margin: 0 auto;
	display: flex;
	font-weight: 600;

	@media (max-width: 500px) {
		flex-direction: column;
		align-items: center;
		justify-content: center;

		div {
			margin-bottom: 15px;
			text-align: center;
		}

		div:last-child {
			margin-bottom: 0;
		}
	}
`;

const BoxContacts = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-around;

	div {
		display: flex;
		flex-direction: row;
		align-items: center;

		p {
			margin-left: 5px;
		}
	}

	@media (max-width: 500px) {
		p:first-child {
			margin-bottom: 10px;
		}
		div {
			justify-content: center;
		}
	}
`;

const Logo = styled.div`
	width: 250px;
	padding: 2px;

	img {
		width: 100%;
		height: 100%;
	}
`;

const Socials = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-around;

	@media (max-width: 500px) {
		flex-direction: row;
	}
`;
