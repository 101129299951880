import styled from "styled-components";
import whatsapp_logo from "../../assets/images/logo_whatsapp.svg";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";

export default function SocialBar() {
	function sendMessageToWhatsapp() {
		const message = `Olá, gostaria de mais informações!`;

		const encodedMessage = encodeURIComponent(message);

		window.open(`https://wa.me/5561983005864?text=${encodedMessage}`);
	}

	function openInstagram() {
		window.open(
			"https://www.instagram.com/akauanoliveiraoficial/?igshid=YmMyMTA2M2Y%3D"
		);
	}

	function openFacebook() {
		window.open("https://www.facebook.com/AkauanFisio?mibextid=ZbWKwL");
	}

	return (
		<>
			<BoxSocials>
				<Socials onClick={openInstagram}>
					<InstagramIcon fontSize="large" />
					<p>akauanoliveiraoficial</p>
				</Socials>
				<Socials onClick={openFacebook}>
					<FacebookIcon fontSize="large" />
					<p>AkauanFisio</p>
				</Socials>
			</BoxSocials>
			<BoxWhatsApp onClick={sendMessageToWhatsapp}>
				<img src={whatsapp_logo} alt="whatsapp" />
			</BoxWhatsApp>
		</>
	);
}

const BoxWhatsApp = styled.div`
	position: fixed;
	bottom: 20px;
	right: 20px;
	z-index: 1;

	&:hover {
		cursor: pointer;
	}

	img {
		width: 80px;
		height: 80px;
		border-radius: 100%;
		border: 2px solid white;
	}

	@media (max-height: 500px) {
		img {
			font-size: 50px;
		}
	}

	@media (max-width: 900px) {
		img {
			width: 60px;
			height: 60px;
		}
	}
`;

const BoxSocials = styled.div`
	display: flex;
	flex-direction: column;

	@media (max-width: 900px) {
		padding: 5px;
	}
`;

const Socials = styled.span`
	margin: 5px;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	align-items: center;

	p {
		margin-left: 5px;
	}

	@media (max-height: 500px) {
		flex-direction: column;
	}
`;
