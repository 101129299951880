import styled from "styled-components";
import Specialty from "../../../components/Specialty";
import liberacao from "../../../assets/images/liberacao.png";
import agulhamento from "../../../assets/images/agulhamento.png";

export default function Courses() {
	return (
		<Box>
			<span id="courses-scroll"></span>
			<Text>Cursos</Text>
			<Container>
				<Specialty
					title="Liberação miofascial"
					text="A liberação miofascial é uma técnica de terapia manual que mobiliza manualmente a fáscia muscular, objetivando aliviar a dor muscular, melhorar a mobilidade e a qualidade dos movimentos articulares e reduzir as aderências dos tecidos cicatriciais."
					image={liberacao}
					color="lightblue"
					date="Em breve"
				/>
				<Specialty
					title="Agulhamento a seco"
					text="O agulhamento a seco, que também é conhecido por “dry needling”, é uma técnica minimamente invasiva que utiliza agulha de acupuntura, a qual penetra na pele e estimula o ponto gatilho, reduzindo a dor no local."
					image={agulhamento}
					color="lightblue"
					date="Em breve"
				/>
			</Container>
		</Box>
	);
}

const Box = styled.div`
	width: 100%;
	height: 100%;
	margin-top: 30px;
`;

const Text = styled.p`
	margin-top: 100px;
	font-size: 40px;
	text-align: center;
	font-family: "Source Sans Pro", sans-serif;
	font-weight: 800;

	@media (max-width: 500px) {
		margin-top: 100px;
	}
`;

const Container = styled.div`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
	align-items: center;
	margin: 20px auto 50px auto;

	@media (max-width: 995px) {
		justify-content: center;
	}

	@media (max-width: 500px) {
		flex-direction: column;
		flex-wrap: initial;
		width: 90%;
	}
`;
