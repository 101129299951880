import { BrowserRouter, Routes, Route } from "react-router-dom";

import GlobalResetStyle from "./assets/css/GlobalResetStyle.css";
import GlobalStyle from "./assets/css/GlobalStyle";
import Main from "./layouts/Main";

function App() {
	return (
		<>
			<GlobalResetStyle />
			<GlobalStyle />
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<Main />} />
				</Routes>
			</BrowserRouter>
		</>
	);
}

export default App;
