import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

    *{
        box-sizing: border-box;
    }

    body {
        font-family: 'Open Sans', sans-serif;
    }

    @page {
        size: auto;
        margin: 0;
    }

    ::-webkit-scrollbar {
        width: 10px;
    }

    ::-webkit-scrollbar-track {
        background: #f1f1f1; 
    }
    
    ::-webkit-scrollbar-thumb {
        background: #888; 
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #555; 
    }

    @keyframes slide-text {
		0% {
			transform: scaleY(0);
			transform-origin: 0% 0%;
		}

		90% {
			transform: scaleY(1);
			transform-origin: 0% 0%;
		}
	}

    @keyframes slide {
		0% {
			transform: scaleX(0);
			transform-origin: 0% 0%;
		}

		100% {
			transform: scaleX(1);
			transform-origin: 0% 0%;
		}
	}
`;

export default GlobalStyle;
