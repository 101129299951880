import TopBar from "../TopBar";
import styled from "styled-components";
import Initial from "./Initial";
import Specialties from "./Specialties";
import Footer from "./Footer";
import Depositions from "./Depositions";
import About from "./About";
import Courses from "./Courses";

export default function Main() {
	return (
		<>
			<TopBar />
			<Box>
				<Container>
					<Initial />
					<Specialties />
					<Courses />
					<Depositions />
					<About />
				</Container>
			</Box>
			<Footer />
		</>
	);
}

const Box = styled.div`
	background-color: white;

	@media (max-width: 500px) {
		padding: 0;
	}
`;

const Container = styled.div`
	width: 100%;
	height: 90%;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	border: none;
	margin-top: 80px;

	@media (max-width: 995px) {
		flex-direction: column;
		align-items: center;
		justify-content: space-evenly;
	}

	@media (max-height: 500px) {
		flex-direction: row;
	}

	@media (max-width: 500px) {
		margin-top: 20px;
	}
`;
