import styled from "styled-components";
import CarouselComp from "../../../components/Carousel";
import renan from "../../../assets/images/depositions/renan.png";

export default function Depositions() {
	const items = [
		{
			image: renan,
			alt: "temp",
			title: "Renan",
			text: "Passei pelo processo de hipnose onde foi uma experiência incrível. A todo momento você está 100% consciente, e completamente relaxado. Recomendo muito á todos essa terapia, pois só há benefícios ao fazê-la.",
		},
	];

	return (
		<Box>
			<span id="depositions-scroll"></span>
			<Text>Depoimentos</Text>
			<Container>
				<CarouselComp height={350} indicator={false}>
					{items.map((item, index) => (
						<ContainerDepositions key={item.text}>
							<div>
								<img
									src={item.image}
									alt={item.alt}
									draggable="false"
								/>
							</div>
							<div>
								<Title>{item.title}</Title>
								<p>{item.text}</p>
							</div>
						</ContainerDepositions>
					))}
				</CarouselComp>
			</Container>
		</Box>
	);
}

const Box = styled.div`
	width: 100%;
	height: 100%;
	margin-top: 30px;
`;

const ContainerDepositions = styled.div`
	display: flex;
	align-items: center;
	width: 70%;
	margin: 0 auto;
	height: 75%;

	div:first-child {
		width: 150px;
		height: 150px;
		margin-right: 20px;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			border-radius: 100%;
			border: 1px solid white;
		}
	}

	div:last-child {
		width: 85%;

		p {
			color: white;
			line-height: 25px;
		}

		@media (max-width: 500px) {
			p:last-child {
				font-size: 13px;
			}
		}
	}

	@media (max-width: 500px) {
		flex-direction: column;
		height: 100%;

		div:first-child {
			margin: 0;
		}

		div:last-child {
			margin-top: 5px;
		}
	}
`;

const Text = styled.p`
	margin-top: 100px;
	font-size: 40px;
	text-align: center;
	font-family: "Source Sans Pro", sans-serif;
	font-weight: 800;

	@media (max-width: 500px) {
		margin-top: 100px;
	}
`;

const Title = styled.p`
	font-size: 20px;
	font-weight: bold;

	@media (max-width: 500px) {
		font-size: 15px;
		text-align: center;
	}
`;

const Container = styled.div`
	width: 100%;
	height: 290px;
	margin-top: 15px;
	background-color: gray;
	padding: 10px;

	@media (max-width: 500px) {
		height: 380px;
	}
`;
